import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-react-intl';

const RateItem = ({
  icon, title, value, simple,
}) => {
  const intl = useIntl();
  const styles = simple ? 'pl-0' : 'shadow-md';
  const message = simple ? `${title}simple` : title;
  return (
    <div className={`${styles} flex space-x-2 p-3 items-center text-gray-700 text-sm`}>
      <img src={icon} alt={title} width={simple ? '20' : ''} />
      <span>{intl.formatMessage({ id: value ? message : `${title}Empty` }, { value })}</span>
    </div>
  );
};

RateItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  simple: PropTypes.bool,
};

export default RateItem;
