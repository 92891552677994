import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-react-intl';
import { H4, H3 } from '../../Texts';
import ListItem from './ListItem';

const UseAgrotokens = ({
  image, mobile, section, icon, reverse,
}) => {
  const intl = useIntl();
  const layout = reverse ? 'lg:flex-row-reverse' : 'lg:flex-row';
  const count = section === 'third' ? 4 : 3;
  const imageStyles = `${section === 'third' ? 'lg:absolute z-neg lg:right-0 lg:h-full' : 'max-w-img'}`;
  const mobileStyles = `${section === 'third' ? 'scale-105' : ''}`;

  return (
    <div
      id={section === 'first' ? 'use-cases' : ''}
      className={`max-w-7xl mx-auto flex flex-col ${layout} lg:px-28 px-4 justify-between pb-24 relative pt-10 items-start`}
    >
      <div className="lg:w-3/6">
        <img className="mb-6" src={icon} alt="use" />
        <H4 className="mb-4">{intl.formatMessage({ id: `use.${section}.heading` })}</H4>
        <H3 className="mb-8">{intl.formatMessage({ id: `use.${section}.subheading` })}</H3>
        <ul className="text-gray-700">
          {Array.from({ length: count }).map((x, i) => (
            <ListItem key={x} section={section} index={i + 1} />
          ))}
        </ul>
      </div>
      <img className={`hidden lg:block lg:-mt-24 ${imageStyles}`} src={image} alt="use" />
      <img className={`lg:hidden transform ${mobileStyles}`} src={mobile || image} alt="use" />
    </div>
  );
};
UseAgrotokens.propTypes = {
  section: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
  image: PropTypes.node,
  mobile: PropTypes.node,
};

export default UseAgrotokens;
