import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import arrow from '../../../images/icons/arrow-2.svg';

const BePartItem = ({ id, links }) => {
  const intl = useIntl();
  const linkStyles = ['text-green-700 hover:text-green-800', 'text-gray-500 hover:text-gray-700'];
  return (
    <div className="be-part-item mb-12">
      <p className="text-2xl  text-gray-900 mb-2 font-medium">
        {intl.formatMessage({ id: `bepart.${id}.title` })}
      </p>
      <p className="text-base text-gray-700 mb-4">
        {intl.formatMessage({ id: `bepart.${id}.subtitle` })}
      </p>
      {links.map((link, index) => (
        <a
          key={link.name}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className={`${linkStyles[index]} mr-9`}
        >
          {intl.formatMessage({ id: `bepart.${link.name}` })}
          {index === 0 && <img className="ml-2 inline" src={arrow} alt="link" />}
        </a>
      ))}

    </div>
  );
};
BePartItem.propTypes = {
  id: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default BePartItem;
