import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

import mainImage from '../../../images/Agrotoken.png';
import mainMobile from '../../../images/hero-mobile.png';
import play from '../../../images/icons/play.svg';
import PrimaryLink from '../../Buttons/PrimaryLink';
import { H1, H3 } from '../../Texts';
import ButtonWithIcon from '../../Buttons/ButtonWithIcon';
import Tooltip from '../../Tooltip';
import Rate from '../../Rate';
import { Link } from 'gatsby';

const Main = () => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();
  return (
    <div
      id="main"
      className="max-w-7xl mx-auto lg:pb-60 relative overflow-hidden">
      <div className="pt-16 px-4 lg:px-32 lg:pt-32 lg:w-4/6">
       <div className='mb-3 flex flex-col'>
         <div className='flex flex-row items-center mb-2'>
           <img src='/images/agroactiva.png' alt='agroactiva' width='220px' height='67px' className='mr-2'/>
           <img src='/images/agro_imago.png' alt='agroactiva' className='mr-2' width='26px' height='19px'/>
           <span className="bg-info-50 text-info-700 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">{intl.formatMessage({ id: 'agroActiveBadge' })}</span>
         </div>
         <Link to='/agroactiva2022'>
           <div className='flex items-center text-gray-500 h-full'>{intl.formatMessage({ id: 'agroActive' })} <span className='ml-2'><img src='/icons/see_more.svg' alt='see more'/></span></div>
         </Link>
       </div>
        <H1>
          {intl.formatMessage({ id: 'title1' })}
          <Tooltip
            underline
            content={intl.formatMessage({ id: 'tokenDefinition' })}
          >
            {intl.formatMessage({ id: 'token' })}
          </Tooltip>

          {intl.formatMessage({ id: 'title2' })}
        </H1>
        <H3 className="mb-8 lg:w-4/5">
          {intl.formatMessage({ id: 'description' })}
        </H3>
        <div className="flex lg:space-x-4 flex-col lg:flex-row space-y-4 lg:space-y-0">
          <PrimaryLink
            to={intl.formatMessage({ id: 'banner.url' })}
            text={intl.formatMessage({ id: 'bepart.contactUs' })}
          />
          <ButtonWithIcon
            onClick={() => setIsOpen(true)}
            text={intl.formatMessage({ id: 'whatAgrotoken' })}
            leftSide={<img className="inline mr-3.5" src={play} alt="" />}
          />
        </div>
        <Rate inline />
        {typeof window !== 'undefined' && (
          <ModalVideo
            channel="youtube"
            autoplay="1"
            isOpen={isOpen}
            videoId={intl.formatMessage({ id: 'videoId' })}
            onClose={() => setIsOpen(false)}
          />
        )}
      </div>
      <img
        src={mainImage}
        alt="AgroToken"
        className="hidden lg:block absolute right-0 top-0 z-neg w-7/12"
      />
      <img src={mainMobile} alt="AgroToken" className="block lg:hidden" />
    </div>
  );
};
export default Main;
