import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-react-intl';

const FeatureItem = ({
  icon, title, description, center = true,
}) => {
  const intl = useIntl();
  const centerStyle = center ? 'lg:items-center' : '';
  return (
    <div className={`flex flex-col flex-auto items-start ${centerStyle} max-w-xs`}>
      <img src={icon} alt="icon" className="mb-5" />
      <p className="text-xl text-gray-700 mb-2 font-medium">
        {intl.formatMessage({ id: title })}
      </p>
      <p className="mb-8">
        {intl.formatMessage({ id: description })}
      </p>
    </div>
  );
};

FeatureItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  center: PropTypes.bool,
};

export default FeatureItem;
