import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import PrimaryButton from '../../Buttons/PrimaryButton';

const Newsletter = () => {
  const intl = useIntl();

  const [email, setEmail] = useState('');
  const [buttonTextId, setbuttonTextId] = useState('newsletter.button');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      setbuttonTextId('newsletter.loading');
      const result = await addToMailchimp(email);
      setbuttonTextId('newsletter.done');
      setEmail('');
      return result;
    }
  };

  return (
    <div className="bg-gray-800">
      <div className="max-w-7xl mx-auto flex lg:py-12 lg:px-28 px-4 py-12 flex-col lg:flex-row">
        <div className="flex-1">
          <h2 className="text-gray-lighter mb-4 lg:text-2xl text-lg">
            {intl.formatMessage({ id: 'newsletter.heading' })}
          </h2>
          <h2 className="text-gray-lighter lg:text-lg mb-4">
            {intl.formatMessage({ id: 'newsletter.subheading' })}
          </h2>
        </div>
        <form className="flex-1 flex h-12 my-3" onSubmit={handleSubmit}>
          <input
            type="email"
            className="h-12 rounded mr-4 flex-1 pl-4 min-w-0"
            placeholder={intl.formatMessage({ id: 'newsletter.placeholder' })}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <PrimaryButton type="submit" text={intl.formatMessage({ id: buttonTextId })} />
        </form>
      </div>
    </div>
  );
};
export default Newsletter;
