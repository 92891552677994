import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import coin from '../../../images/press/1.png';
import valor from '../../../images/press/2.png';
import cripto from '../../../images/press/3.png';
import nacion from '../../../images/press/4.png';
import iproup from '../../../images/press/5.png';
import PressItem from './PressItem';

const Press = () => {
  const intl = useIntl();

  const pressItems = [
    { image: coin, alt: 'coin' },
    { image: valor, alt: 'Valor' },
    { image: nacion, alt: 'la Nación' },
    { image: cripto, alt: 'cripto247' },
    { image: iproup, alt: 'iPro up' },
  ];

  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto text-center py-20">
        <p className="mb-8">{intl.formatMessage({ id: 'press' })}</p>
        <div className="px-4 md:px-0 flex-wrap space-y-6 md:space-x-4 space-y-reverse flex justify-center lg:justify-between mt-8 lg:mx-28 items-start">
          {pressItems.map((item) => (
            <PressItem key={item.alt} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default Press;
