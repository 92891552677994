/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import * as React from 'react';

import { useIntl } from 'gatsby-plugin-react-intl';
import Layout from '../components/layout';
import Agrotokens from '../components/Sections/Agrotokens';
import Features from '../components/Sections/Features';
import Main from '../components/Sections/Main';
import Press from '../components/Sections/Press';
import UseAgrotokens from '../components/Sections/UseAgrotokens';

import use1 from '../images/icons/use-1.svg';
import use2 from '../images/icons/use-2.svg';
import use3 from '../images/icons/use-3.svg';
import image3 from '../images/use-3.png';
import image3Mobile from '../images/use-3-mobile.png';
import Blockchain from '../components/Sections/Blockchain';
import BePart from '../components/Sections/BePart';
import Newsletter from '../components/Sections/Newsletter';
import Banner from '../components/Sections/Banner';

// markup
const IndexPage = () => {
  const intl = useIntl();
  const firstImage = require(`../images/use-1-${intl.locale}.png`).default;
  const secondmage = require(`../images/use-2-${intl.locale}.png`).default;
  return (
    <Layout>
      <Main />
      <Press />
      <Features />
      <Agrotokens />
      <UseAgrotokens image={firstImage} icon={use1} section="first" />
      <UseAgrotokens image={secondmage} icon={use2} section="second" reverse />
      <UseAgrotokens
        image={image3}
        mobile={image3Mobile}
        icon={use3}
        section="third"
      />
      <Blockchain />
      <BePart />
      <Newsletter />
      <Banner />
    </Layout>
  );
};

export default IndexPage;
