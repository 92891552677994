import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-react-intl';
import checklist from '../../../images/icons/checklist.svg';

const ListItem = ({ section, index }) => {
  const intl = useIntl();
  return (
    <li>
      <div className="flex mb-5 items-start">
        <img className="mr-3" src={checklist} alt="checklist" />
        <span>{intl.formatMessage({ id: `use.${section}.list.${index}` })}</span>
      </div>
    </li>
  );
};

ListItem.propTypes = {
  section: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default ListItem;
