import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-react-intl';

import PrimaryLink from '../../Buttons/PrimaryLink';

const Banner = ({ hideRegister }) => {
  const intl = useIntl();
  return (
    <div className="px-4 pt-12 lg:pt-16 lg:px-28 bg-gray-900 text-gray-lighter text-center">
      <div className="max-w-7xl mx-auto">
        <h2 className="mb-4 text-xl lg:text-3xl leading-snug">{intl.formatMessage({ id: 'banner.heading' })}</h2>
        <h3 className="mb-8 lg:text-xl  leading-snug">{intl.formatMessage({ id: 'banner.subheading' })}</h3>
        {!hideRegister && <PrimaryLink className="mb-12" to={intl.formatMessage({ id: 'banner.url' })} text={intl.formatMessage({ id: 'banner.button' })} />}
      </div>
      <hr className="border-gray-700" />
    </div>
  );
};

Banner.propTypes = {
  hideRegister: PropTypes.bool,
};

export default Banner;
