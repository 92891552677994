import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import { H2, H3 } from '../../Texts';
import BePartItem from './BePartItem';

import image from '../../../images/bepart.png';

const BePart = () => {
  const intl = useIntl();
  const items = [
    {
      id: 'farmers',
      links: [
        {
          name: 'register',
          url: intl.formatMessage({ id: 'bepart.farmers.url' }),
        },
      ],
    },
    {
      id: 'merchants',
      links: [
        {
          name: 'register',
          url: intl.formatMessage({ id: 'bepart.merchants.url' }),
        },
      ],
    },
    { id: 'storage', links: [] },
    { id: 'investors', links: [] },
  ];
  return (
    <div
      id="be-part"
      className="max-w-7xl mx-auto flex flex-col lg:flex-row px-4 lg:px-0 pb-20 lg:pb-0"
    >
      <div className="flex-1">
        <div className="lg:pt-32 lg:pl-28">
          <p className="mb-3 text-green-700">Features</p>
          <H2>{intl.formatMessage({ id: 'bepart.heading' })}</H2>
          <H3>{intl.formatMessage({ id: 'bepart.subheading' })}</H3>
        </div>
        <img src={image} alt="BePart" className="lg:pl-28" />
      </div>
      <div className="flex-1 lg:pt-60 lg:px-28">
        {items.map((item) => (
          <BePartItem key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
};
export default BePart;
