import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import grains from '../../../images/icons/grains.svg';

import { H2, H3 } from '../../Texts';
import Rate from '../../Rate';

const Agrotokens = () => {
  const intl = useIntl();

  return (
    <div className="max-w-7xl mx-auto px-4 pb-20 lg:pb-24 lg:px-28 lg:text-center flex flex-col items-center">
      <H2 className="lg:w-4/5">{intl.formatMessage({ id: 'agrotokens.heading' })}</H2>
      <H3 className="mb-12">{intl.formatMessage({ id: 'agrotokens.subheading' })}</H3>
      <img className="mb-5" src={grains} alt="grains" />
      <H3 className="mb-8 font-medium">{intl.formatMessage({ id: 'agrotokens.conversion' })}</H3>
      <Rate />
    </div>
  );
};
export default Agrotokens;
