import React from 'react';
import PropTypes from 'prop-types';
import './tooltip.scss';

const Tooltip = ({
  content, children, underline, small,
}) => (
  <span className={`tooltip ${underline ? 'underlined' : ''} ${small ? 'small' : ''}`}>
    {children}
    <span className="tooltiptext">{content}</span>
  </span>
);

export default Tooltip;
Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.string.isRequired,
  underline: PropTypes.bool,
  small: PropTypes.bool,
};
