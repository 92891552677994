import React from 'react';
import PropTypes from 'prop-types';
import spinner from '../../../images/spinner.svg';

const PrimaryLink = ({
  text,
  type,
  onClick,
  loading,
  className = '',
  disabled,
}) => (
  <button
    className={`${className} bg-green hover:bg-green-700 text-white py-3 px-5 shadow-sm rounded-sm text-center`}
    type={type}
    onClick={onClick}
    disabled={disabled}
  >
    {text}
    {loading && <img className="ml-2 inline w-5" src={spinner} alt="spinner" />}
  </button>
);
export default PrimaryLink;

PrimaryLink.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};
