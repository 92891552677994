import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { H2, H3 } from '../../Texts';
import FeatureItem from '../Features/FeatureItem';
import icon1 from '../../../images/icons/icon-1.svg';
import icon5 from '../../../images/icons/icon-5.svg';
import icon6 from '../../../images/icons/icon-6.svg';
import arrow from '../../../images/icons/arrow.svg';

import ethereum from '../../../images/tools/ethereum.png';
import polygon from '../../../images/tools/polygon.png';
import algoran from '../../../images/tools/algoran.png';
import etherscan from '../../../images/tools/etherscan.png';
import blockchain from '../../../images/blockchain.png';

const Blockchain = () => {
  const intl = useIntl();

  const bgStyles = {
    backgroundImage: `url(${blockchain})`,
  };

  const features = [
    {
      title: 'blockchain.title1',
      description: 'blockchain.description1',
      icon: icon5,
    },
    {
      title: 'blockchain.title2',
      description: 'blockchain.description2',
      icon: icon6,
    },
    {
      title: 'blockchain.title3',
      description: 'blockchain.description3',
      icon: icon1,
    },
  ];
  return (
    <div
      className="bg-no-repeat bg-100 lg:bg-60 bg-right-bottom"
      style={bgStyles}
    >
      <div className="max-w-7xl mx-auto pt-20 p-bg px-4 lg:px-20 lg:py-20">
        <div className="lg:w-7/12">
          <p className="mb-3 text-green-700">Blockchain</p>
          <H2>{intl.formatMessage({ id: 'blockchain.heading' })}</H2>
          <H3 className="mb-12">
            {intl.formatMessage({ id: 'blockchain.subheading' })}
          </H3>
        </div>
        <div className="flex flex-wrap lg:space-x-4 mb-12">
          {features.map((item) => (
            <FeatureItem key={item.title} {...item} center={false} />
          ))}
        </div>
        <p className="mb-6">{intl.formatMessage({ id: 'blockchain.built' })}</p>
        <div className="flex flex-wrap items-center mb-12">
          <img
            className="w-28 mb-4 mr-4 lg:mb-8 lg:mr-8"
            src={ethereum}
            alt="Ethereum"
          />
          <img
            className="w-28 mb-4 mr-4 lg:mb-8 lg:mr-8"
            src={polygon}
            alt="Polygon"
          />
          <img
            className="w-28 mb-4 mr-4 lg:mb-8 lg:mr-8"
            src={algoran}
            alt="Algoran"
          />
        </div>
        <div>
          <img className="inline" src={arrow} alt="Ethereum" />
          <span className="mx-2">
            {intl.formatMessage({ id: 'blockchain.find1' })}
            <a
              className="underline"
              href="https://etherscan.io/token/0xb99c9e436a630202e40c90ba9b65dcb610200066"
            >
              SOYA
            </a>
            {', '}
            <a
              className="underline"
              href="https://etherscan.io/token/0x891fde3771f8095e437754fc1a4aad6937378491"
            >
              CORA
            </a>
            {intl.formatMessage({ id: 'blockchain.findConjuntion' })}
            <a
              className="underline"
              href="https://etherscan.io/token/0x1d48257386165d44e303c579386c46089b2775f5"
            >
              WHEA
            </a>
            {intl.formatMessage({ id: 'blockchain.find2' })}
          </span>
          <img className="inline w-20" src={etherscan} alt="Ethereum" />
        </div>
      </div>
    </div>
  );
};
export default Blockchain;
