/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';

import { useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import soya from '../../images/tokens/Tokens.svg';
import whea from '../../images/tokens/Tokens-1.svg';
import cora from '../../images/tokens/Tokens-2.svg';
import question from '../../images/icons/question.svg';
import RateItem from './RateItem';
import Tooltip from '../Tooltip';

const Rate = ({ inline = false }) => {
  const intl = useIntl();
  const [currency, setCurrency] = useState({});

  useEffect(() => {
    fetch(process.env.CURRENCY_ENDPOINT).then((response) => response.json())
      .then((data) => {
        const res = {};
        data.forEach((d) => { res[d.exchange] = d.value; });
        setCurrency(res);
      });
  }, []);

  const styles = inline ? 'mt-8' : 'mb-5 justify-center';
  return (
    <div className="text-sm text-center text-gray-500 mt-">
      <div className={`flex space-x-2 items-center ${styles}`}>
        <p className="font-medium">{intl.formatMessage({ id: 'rates.title' })}</p>
        <Tooltip small content={intl.formatMessage({ id: 'rates.indexes' })}>
          <img src={question} alt="question" />
        </Tooltip>
      </div>
      <div className={`${inline ? '' : 'justify-center space-y-4'} flex lg:space-x-8 flex-row flex-wrap lg:space-y-0`}>
        {currency.value_agrosoy_usd && <RateItem simple={inline} icon={soya} title="rates.soya" value={currency.value_agrosoy_usd} />}
        {currency.value_cora_usd && <RateItem simple={inline} icon={cora} title="rates.cora" value={currency.value_cora_usd} />}
        {currency.value_whea_usd && <RateItem simple={inline} icon={whea} title="rates.whea" value={currency.value_whea_usd} />}
      </div>
    </div>
  );
};

Rate.propTypes = {
  inline: PropTypes.bool,
};

export default Rate;
