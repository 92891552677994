import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { H2, H3 } from '../../Texts';
import './styles.scss';

import FeatureItem from './FeatureItem';
import icon1 from '../../../images/icons/icon-1.svg';
import icon2 from '../../../images/icons/icon-2.svg';
import icon3 from '../../../images/icons/icon-3.svg';

import soya from '../../../images/tokens/Tokens.svg';
import whea from '../../../images/tokens/Tokens-1.svg';
import cora from '../../../images/tokens/Tokens-2.svg';

const Features = () => {
  const intl = useIntl();
  const features = [
    {
      title: 'features.title1',
      description: 'features.description1',
      icon: icon1,
    },
    {
      title: 'features.title2',
      description: 'features.description2',
      icon: icon2,
    },
    {
      title: 'features.title3',
      description: 'features.description3',
      icon: icon3,
    },
  ];

  return (
    <div className="bg-gradient">
      <div id="we-do" className="max-w-7xl mx-auto px-4 pb-20 lg:pb-24 lg:px-28 lg:text-center">
        <div className="flex justify-start lg:justify-center space-x-4 mb-5">
          <img src={soya} alt="soya" />
          <img src={cora} alt="corn" />
          <img src={whea} alt="wheat" />
        </div>
        <H2>{intl.formatMessage({ id: 'feature1.heading' })}</H2>
        <H3 className="mb-16 lg:w-3/5 mx-auto">{intl.formatMessage({ id: 'feature1.subheading' })}</H3>
        <div className="flex flex-wrap lg:space-x-4 lg:justify-center">
          {features.map((item) => (
            <FeatureItem key={item.title} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default Features;
